import axios from 'axios'
import api from '@/api'

const data = {
  data: [],
  default: []
}

const ebsCatalogItemLearnerEnrolments = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getEbsCatalogItemLearnerEnrolments: state => {
      return state.data
    },
    getEbsCatalogItemLearnerEnrolmentsById: state => enrolmentId => {
      return enrolmentId ? state.data.find(obj => obj.id === enrolmentId) : false
    },
    getEbsCatalogItemLearnerEnrolmentsLatestCompletion: state => {
      const enrolments = JSON.parse(JSON.stringify(state.data))
      const completedEnrolments = enrolments.filter(obj => obj.state === 'completed')
      let latestCompletedEnrolment

      for (let enrolment of completedEnrolments) {
        if (!latestCompletedEnrolment || new Date(latestCompletedEnrolment.createdAt) < new Date(enrolment.createdAt)) {
          latestCompletedEnrolment = enrolment
        }
      }

      return latestCompletedEnrolment
    },
    getEbsCatalogItemLearnerEnrolmentsLatestFail: state => {
      const enrolments = JSON.parse(JSON.stringify(state.data))
      const failedEnrolments = enrolments.filter(obj => obj.state === 'failed')
      let latestFailedEnrolment

      for (let enrolment of failedEnrolments) {
        if (!latestFailedEnrolment || new Date(latestFailedEnrolment.createdAt) < new Date(enrolment.createdAt)) {
          latestFailedEnrolment = enrolment
        }
      }

      return latestFailedEnrolment
    }
  },
  mutations: {
    EBS_CATALOGITEM_LEARNER_ENROLMENTS_CLEAR (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
      state.default = JSON.parse(JSON.stringify(data.default))
    },
    EBS_CATALOGITEM_LEARNER_ENROLMENTS_SET (state, payload) {
      state.data = payload
    }
  },
  actions: {
    ebsCatalogItemLearnerEnrolmentsGet ({ commit, getters }, data) {
      return new Promise((resolve, reject) => {
        const catalogItemId = data
        const learnerId = getters.getAuthUserSecondStageTokenDataUserId
        axios({
          method: 'get',
          url: `${api.learningactivities}/learner-enrolments-by-catalog-item/${catalogItemId}/${learnerId}`
        }).then(response => {
          commit('EBS_CATALOGITEM_LEARNER_ENROLMENTS_SET', response.data)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsCatalogItemLearnerEnrolmentsClear ({ commit }) {
      commit('EBS_CATALOGITEM_LEARNER_ENROLMENTS_CLEAR')
    }
  }
}

export default ebsCatalogItemLearnerEnrolments
