import axios from 'axios'
import api from '@/api'
import { toCapitalize } from "@/functions";

const CancelToken = axios.CancelToken
let cancelEbsCoursesGet

const data = {
  pagination: {},
  data: [],
  default: '',
  custom: {
    requestPending: false
  }
}

const ebsCourses = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getEbsCourses: state => {
      return state.data
    },
    getEbsCoursesMappedLabelValue: state => {
      const rawItems = state.data
      if (!rawItems.length) return []
      return rawItems.map((item) => {
        return {
          label: item.name + (item.id || item.catalogItemId),
          customLabel: item.name,
          value: (item.id || item.catalogItemId)
        }
      })
    },
    getEbsCoursesMappedIdNameStatusLabels: state => {
      const rawItems = state.data

      if (!rawItems.length) return []
      return rawItems.map((item) => {
        return {
          id: (item.id || item.catalogItemId),
          name: item.name,
          status: item.status,
          type: toCapitalize(item.type === 'scorm' ? 'digital' : item.type),
          labels: item.labels
        }
      })
    },
    getEbsCoursesMappedIdNameEnrolmentStatus: state => {
      const rawItems = state.data
      if (!rawItems.length) return []
      return rawItems.map((item) => {
        return {
          id: item.id,
          name: item.name,
          enrolmentStatus: item.enrolmentStatus,
          disabled: item.enrolmentStatus === 'enrolled' || item.enrolmentStatus === 'not_started' || item.enrolmentStatus === 'in_progress'
        }
      })
    },
    getEbsCoursesReleasedMappedLabelValue: state => {
      const rawItems = state.data
      if (!rawItems.length) return []
      return rawItems.map((item) => {
        return {
          label: item.name + item.courseId,
          customLabel: `${item.name} (${item.majorVersion}.${item.minorVersion})`,
          value: item.courseId,
          customValue: item.catalogItemVersionId || item.versionId
        }
      })
    },
    getEbsCoursesReleasedMappedIdNameStatus: state => {
      const rawItems = state.data
      if (!rawItems.length) return []
      return rawItems.map((item) => {
        return {
          id: item.courseId,
          name: `${item.name} (${item.majorVersion}.${item.minorVersion})`,
          labels: item.labels ? item.labels.map(label => Object.values(label)[0]) : [],
          extraData: item.catalogItemVersionId || item.versionId
        }
      })
    },
    getEbsCoursesMappedLabelValueExternalTrainings: state => {
      let rawItems = JSON.parse(JSON.stringify(state.data))

      return rawItems.map((item) => {
        return {
          label: item.name + (item.id || item.catalogItemId),
          customLabel: item.externalCompletions !== null && item.externalCompletions > 0 ? `${item.name} <span>Related to ${item.externalCompletions} external ${item.externalCompletions === 1 ? 'record' : 'records'}</span>` : item.name,
          value: item.id || item.catalogItemId
        }
      })
    },
    getEbsCoursesMappedIdNameStatusExternalTrainings: state => {
      let rawItems = JSON.parse(JSON.stringify(state.data))

      return rawItems.map((item) => {
        return {
          id: item.id || item.catalogItemId,
          name: item.name,
          type: item.type.toLowerCase() === 'digital learning' ? 'Digital' : item.type,
          externalTrainings: item.externalCompletions
        }
      })
    },
    getEbsCoursesPagination: state => {
      return state.pagination
    },
    getEbsCoursesRequestPending: state => {
      return state.custom.requestPending
    }
  },
  mutations: {
    EBS_COURSES_CLEAR (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
    },
    EBS_COURSES_CLEAR_PAGINATION (state) {
      state.pagination = JSON.parse(JSON.stringify(data.pagination))
    },
    EBS_COURSES_SET (state, payload) {
      state.data = payload
    },
    EBS_COURSES_SET_CUSTOM (state, payload) {
      state.custom = payload
    },
    EBS_COURSES_SET_PAGINATION (state, payload) {
      state.pagination = payload
    },
    EBS_COURSES_REQUEST_PENDING (state, payload) {
      state.custom.requestPending = payload
    }
  },
  actions: {
    ebsCoursesGetCombined ({ commit }, data) {
      return new Promise((resolve, reject) => {
        let queryUrlParams = data ? data.queryUrlParams : '?pageSize=9999'
        const workspaceId = data && data.requestData && data.requestData.workspaceId ? (data.requestData.workspaceId !== undefined ? data.requestData.workspaceId : '') : ''
        const customParams = data && data.customParams ? Object.entries(data.customParams).map(obj => `&filters[${obj[0]}]=${obj[1]}`).join() : ''

        commit('EBS_COURSES_CLEAR')
        commit('EBS_COURSES_CLEAR_PAGINATION')
        commit('EBS_COURSES_REQUEST_PENDING', true)

        axios({
          method: 'get',
          url: `${api.courseCatalog}/v2/catalog/admin${workspaceId ? '/' + workspaceId : ''}${queryUrlParams}${customParams}`
        }).then(response => {
          commit('EBS_COURSES_SET', response.data.data)
          commit('EBS_COURSES_SET_PAGINATION', response.data.pagination)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        }).finally(() => {
          commit('EBS_COURSES_REQUEST_PENDING', false)
        })
      })
    },
    ebsCoursesGetCancelRequests () {
      if (cancelEbsCoursesGet) cancelEbsCoursesGet()
    },
    ebsCoursesGetWithCatalogItems ({ commit, getters, dispatch }, data) {
      dispatch('ebsCoursesGetCancelRequests')

      return new Promise((resolve, reject) => {
        let queryUrlParams = data ? data.queryUrlParams : '?pageSize=9999'
        const workspaceId = getters.getAuthUserSecondStageTokenDataWorkspaceId
        const loggedInUserWorkspaceId = getters.getAuthUserSecondStageTokenDataWorkspaceId
        const loggedInUserCustomerId = getters.getAuthUserSecondStageTokenDataCustomerId

        commit('EBS_COURSES_CLEAR')
        commit('EBS_COURSES_CLEAR_PAGINATION')

        axios({
          method: 'get',
          cancelToken: new CancelToken(function executor (ex) {
            cancelEbsCoursesGet = ex
          }),
          url: `${api.courseCatalog}/v3/catalog-items-with-courses/${workspaceId}${queryUrlParams}`
        }).then(response => {
          let responseData = response.data.data
          const customers = response.data.pagination.customers
          const workspaces = response.data.pagination.workspaces

          for (let item of responseData) {
            if (item.licenceCustomerId && item.licenceCustomerId !== loggedInUserCustomerId) {
              const selectedCustomer = customers.find(customer => customer.id === item.licenceCustomerId)
              item.sharedFrom = selectedCustomer ? selectedCustomer.name : ''
            } else if (item.originWorkspaceId && item.originWorkspaceId !== loggedInUserWorkspaceId) {
              const selectedWorkspace = workspaces.find(workspace => workspace.id === item.originWorkspaceId)
              item.sharedFrom = selectedWorkspace ? selectedWorkspace.name : ''
            } else {
              item.sharedFrom = null
            }
          }

          commit('EBS_COURSES_SET', responseData)
          commit('EBS_COURSES_SET_PAGINATION', response.data.pagination)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsCoursesGet ({ commit }, data) {
      return new Promise((resolve, reject) => {
        const queryUrlParams = data ? data.queryUrlParams : '?pageSize=9999'

        commit('EBS_COURSES_CLEAR')
        commit('EBS_COURSES_CLEAR_PAGINATION')

        axios({
          method: 'get',
          url: `${api.courseCatalog}/v2/courses${queryUrlParams}`
        }).then(response => {
          commit('EBS_COURSES_SET', response.data.data)
          commit('EBS_COURSES_SET_PAGINATION', response.data.pagination)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsCoursesGetAdmin ({ commit }, data) {
      return new Promise((resolve, reject) => {
        let queryUrlParams = data ? data.queryUrlParams : '?pageSize=9999'
        const workspaceId = data && data.requestData && data.requestData.workspaceId ? (data.requestData.workspaceId !== undefined ? data.requestData.workspaceId : '') : ''
        const customParams = data && data.customParams ? Object.entries(data.customParams).map(obj => `&filters[${obj[0]}]=${obj[1]}`).join() : ''
        const externalTrainings = data && data.requestData && data.requestData.externalTrainings ? data.requestData.externalTrainings : ''
        const showEnrolmentsFor = data && data.requestData && data.requestData.showEnrolmentsFor ? data.requestData.showEnrolmentsFor : ''
        queryUrlParams += externalTrainings ? '&externalTrainings=1' : ''
        queryUrlParams += showEnrolmentsFor ? `&showEnrolmentsFor=${showEnrolmentsFor}` : ''

        commit('EBS_COURSES_CLEAR')
        commit('EBS_COURSES_CLEAR_PAGINATION')
        commit('EBS_COURSES_REQUEST_PENDING', true)

        axios({
          method: 'get',
          url: `${api.courseCatalog}/v2/catalog/admin${workspaceId ? '/' + workspaceId : ''}${queryUrlParams}${customParams}`
        }).then(response => {
          commit('EBS_COURSES_SET', response.data.data)
          commit('EBS_COURSES_SET_PAGINATION', response.data.pagination)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        }).finally(() => {
          commit('EBS_COURSES_REQUEST_PENDING', false)
        })
      })
    },
    ebsCoursesGetForAutoenrolmentsWorkflows ({ commit, getters }, data) {
      return new Promise((resolve, reject) => {
        let queryUrlParams = data ? data.queryUrlParams : '?pageSize=9999'
        const workspaceId = data && data.requestData && data.requestData.workspaceId ? (data.requestData.workspaceId !== undefined ? data.requestData.workspaceId : '') : getters.getAuthUserSecondStageTokenDataWorkspaceId
        const customParams = data && data.customParams ? Object.entries(data.customParams).map(obj => `&filters[${obj[0]}]=${obj[1]}`).join() : ''
        const externalTrainings = data && data.requestData && data.requestData.externalTrainings ? data.requestData.externalTrainings : ''
        const showEnrolmentsFor = data && data.requestData && data.requestData.showEnrolmentsFor ? data.requestData.showEnrolmentsFor : ''
        queryUrlParams += externalTrainings ? '&externalTrainings=1' : ''
        queryUrlParams += showEnrolmentsFor ? `&showEnrolmentsFor=${showEnrolmentsFor}` : ''

        commit('EBS_COURSES_CLEAR')
        commit('EBS_COURSES_CLEAR_PAGINATION')
        commit('EBS_COURSES_REQUEST_PENDING', true)

        axios({
          method: 'get',
          url: `${api.courseCatalog}/v2/catalog/for-autoenrolments-workflows/${workspaceId}${queryUrlParams}${customParams}`
        }).then(response => {
          commit('EBS_COURSES_SET', response.data.data)
          commit('EBS_COURSES_SET_PAGINATION', response.data.pagination)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        }).finally(() => {
          commit('EBS_COURSES_REQUEST_PENDING', false)
        })
      })
    },
    ebsCoursesGetForAdminEnrolments ({ commit }, data) {
      return new Promise((resolve, reject) => {
        let queryUrlParams = data ? data.queryUrlParams : '?pageSize=9999'
        const workspaceId = data && data.requestData && data.requestData.workspaceId ? (data.requestData.workspaceId !== undefined ? data.requestData.workspaceId : '') : ''
        const profileId = data && data.requestData && data.requestData.profileId ? (data.requestData.profileId !== undefined ? data.requestData.profileId : '') : ''
        const customParams = data && data.customParams ? Object.entries(data.customParams).map(obj => `&filters[${obj[0]}]=${obj[1]}`).join() : ''
        const showEnrolmentsFor = data && data.requestData && data.requestData.showEnrolmentsFor ? data.requestData.showEnrolmentsFor : ''
        queryUrlParams += showEnrolmentsFor ? `&showEnrolmentsFor=${showEnrolmentsFor}` : ''

        commit('EBS_COURSES_CLEAR')
        commit('EBS_COURSES_CLEAR_PAGINATION')

        axios({
          method: 'get',
          url: `${api.courseCatalog}/v2/catalog/for-admin-enrolments/${workspaceId}/${profileId}${queryUrlParams}${customParams}`
        }).then(response => {
          commit('EBS_COURSES_SET', response.data.data)
          commit('EBS_COURSES_SET_PAGINATION', response.data.pagination)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsCoursesGetLearner ({ commit, getters, dispatch }, data) {
      dispatch('ebsCoursesGetCancelRequests')

      return new Promise((resolve, reject) => {
        let queryUrlParams = data ? data.queryUrlParams : '?pageSize=9999'
        const workspaceId = data && data.requestData && data.requestData.workspaceId ? data.requestData.workspaceId : getters.getAuthUserSecondStageTokenDataWorkspaceId
        const unitId = data && data.requestData && data.requestData.unitId ? data.requestData.unitId : getters.getAuthUserSecondStageTokenDataOrganisationUnit
        const showEnrolmentsFor = data && data.requestData && data.requestData.showEnrolmentsFor ? data.requestData.showEnrolmentsFor : ''
        queryUrlParams += showEnrolmentsFor ? `&showEnrolmentsFor=${showEnrolmentsFor}` : ''

        commit('EBS_COURSES_CLEAR')
        commit('EBS_COURSES_CLEAR_PAGINATION')

        axios({
          method: 'get',
          cancelToken: new CancelToken(function executor (ex) {
            cancelEbsCoursesGet = ex
          }),
          url: `${api.courseCatalog}/v2/catalog/learner${workspaceId ? '/' + workspaceId : ''}${unitId ? '/' + unitId : ''}${queryUrlParams}`
        }).then(response => {
          commit('EBS_COURSES_SET', response.data.data)
          commit('EBS_COURSES_SET_PAGINATION', response.data.pagination)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsCoursesGetLearnerSpecific ({ commit }, data) {
      return new Promise((resolve, reject) => {
        const queryUrlParams = data ? data.queryUrlParams : '?pageSize=9999'
        const profileId = data.requestData.profileId

        commit('EBS_COURSES_CLEAR')
        commit('EBS_COURSES_CLEAR_PAGINATION')

        axios({
          method: 'get',
          url: `${api.courseCatalog}/v2/catalog/single-learner/${profileId}${queryUrlParams}`
        }).then(response => {
          commit('EBS_COURSES_SET', response.data.data)
          commit('EBS_COURSES_SET_PAGINATION', response.data.pagination)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsCoursesGetReleased ({ commit }, data) {
      return new Promise((resolve, reject) => {
        const queryUrlParams = data ? data.queryUrlParams : '?pageSize=9999'
        commit('EBS_COURSES_CLEAR')
        commit('EBS_COURSES_CLEAR_PAGINATION')

        axios({
          method: 'get',
          url: `${api.courseCatalog}/v2/courses/latest-released${queryUrlParams}`
        }).then(response => {
          commit('EBS_COURSES_SET', response.data.data)
          commit('EBS_COURSES_SET_PAGINATION', response.data.pagination)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsCoursesGetOldLMS ({ commit, getters }) {
      axios({
        method: 'post',
        url: `${api.courseCatalog}/courses/find`,
        data: {
          domain: getters.getAuthUserSecondStageTokenDataDomain,
          status: 0,
          simplified: true
        }
      }).then(function (response) {
        commit('EBS_COURSES_SET', response.data)
      })
    }
  }
}

export default ebsCourses
