import axios from 'axios'
import api from '@/api'
import { toDataURL } from '@/functions'

const defaultHtml = `<h1 style="text-align: center;">Certificate of Completion</h1>\n\n<h3 style="text-align: center;">This is to certify that</h3><h2 style="text-align: center;">{{ learner/firstname }} {{ learner/lastname }}</h2>\n\n<h3 style="text-align: center;">Successfully completed the course entitled</h3><h2 style="text-align: center;">{{ course/name }}</h2>\n\n<p style="text-align: center;">Award date \n<strong>{{ course/completionDate }}</strong></p>`

const data = {
  data: {
    id: '',
    tags: [],
    name: '',
    html: defaultHtml,
    description: '<p></p>',
    frame: 'course_certificate',
    defaults: {},
    workspaceDefault: false,
    workspaceId: '',
    preview: '',
    logo: {
      fileId: '',
      position: 'left'
    },
    footer: {
      fileId: '',
      position: 'left'
    }
  },
  default: '',
  custom: {
    changed: false
  }
}

const ebsCertificate = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getEbsCertificate: state => {
      return state.data
    },
    getEbsCertificateName: state => {
      return state.data.name
    },
    getEbsCertificateId: state => {
      return state.data.id
    },
    getEbsCertificateTags: state => {
      return state.data.tags
    },
    getEbsCertificateFrame: state => {
      return state.data.frame
    },
    getEbsCertificateHtml: state => {
      return state.data.html
    },
    getEbsCertificatePreview: state => {
      return state.data.preview
    },
    getEbsCertificateWorkspaceDefault: state => {
      return state.data.workspaceDefault
    },
    getEbsCertificateWorkspaceId: state => {
      return state.data.workspaceId
    },
    getEbsCertificateDescription: state => {
      return state.data.description
    },
    getEbsCertificateLogo: state => {
      return state.data.logo
    },
    getEbsCertificateLogoImage: state => {
      return state.data.logo.fileId
    },
    getEbsCertificateLogoPosition: state => {
      return state.data.logo.position
    },
    getEbsCertificateFooter: state => {
      return state.data.footer
    },
    getEbsCertificateFooterImage: state => {
      return state.data.footer.fileId
    },
    getEbsCertificateFooterPosition: state => {
      return state.data.footer.position
    },
    getEbsCertificateCustomChanged: state => {
      return (
          state.data !== '' &&
          state.default !== '' &&
          (
              state.data.workspaceDefault !== state.default.workspaceDefault ||
              state.data.name !== state.default.name ||
              state.data.html !== state.default.html ||
              state.data.logo !== state.default.logo ||
              state.data.footer !== state.default.footer
          )
      )
    }
  },
  mutations: {
    EBS_CERTIFICATE_GET (state, payload) {
      let payloadData = JSON.parse(JSON.stringify(payload))
      payloadData.logo = payloadData.logo || data.data.logo
      payloadData.footer = payloadData.footer || data.data.footer
      state.data = JSON.parse(JSON.stringify(payloadData))
      state.default = JSON.parse(JSON.stringify(payloadData))
    },
    EBS_CERTIFICATE_CLEAR (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
      state.default = JSON.parse(JSON.stringify(data.data))
      state.custom = JSON.parse(JSON.stringify(data.custom))
    },
    EBS_CERTIFICATE_RESET (state) {
      state.data = JSON.parse(JSON.stringify(state.default))
    },
    EBS_CERTIFICATE_SET_NAME (state, payload) {
      state.data.name = payload
    },
    EBS_CERTIFICATE_SET_TAGS (state, payload) {
      state.data.tags = payload
    },
    EBS_CERTIFICATE_SET_FRAME (state, payload) {
      state.data.frame = payload
    },
    EBS_CERTIFICATE_SET_HTML (state, payload) {
      state.data.html = payload
    },
    EBS_CERTIFICATE_SET_PREVIEW (state, payload) {
      state.data.preview = payload
    },
    EBS_CERTIFICATE_SET_WORKSPACEDEFAULT (state, payload) {
      state.data.workspaceDefault = payload
    },
    EBS_CERTIFICATE_SET_WORKSPACEID (state, payload) {
      state.data.workspaceId = payload
    },
    EBS_CERTIFICATE_SET_DESCRIPTION (state, payload) {
      state.data.description = payload
    },
    EBS_CERTIFICATE_SET_LOGO_ID (state, payload) {
      state.data.logo.fileId = payload[0]
    },
    EBS_CERTIFICATE_SET_LOGO_POSITION (state, payload) {
      state.data.logo.position = payload
    },
    EBS_CERTIFICATE_SET_FOOTER_ID (state, payload) {
      state.data.footer.fileId = payload[0]
    },
    EBS_CERTIFICATE_SET_FOOTER_POSITION (state, payload) {
      state.data.footer.position = payload
    },
    EBS_CERTIFICATE_REMOVE_LOGO (state) {
      state.data.logo.fileId = ''
    },
    EBS_CERTIFICATE_REMOVE_FOOTER (state) {
      state.data.footer.fileId = ''
    }
  },
  actions: {
    ebsCertificateGet ({ commit }, data) {
      return new Promise((resolve, reject) => {
        const certificateId = data.certificateId
        const workspaceId = data.workspaceId

        axios({
          method: 'get',
          url: `${ api.learningactivities }/certificate/content/${ certificateId }${ workspaceId ? `?workspaceId=` + workspaceId : '' }`
        }).then(response => {
          commit('EBS_CERTIFICATE_GET', response.data)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsCertificateCreate ({ state, getters, dispatch }, data) {
      return new Promise((resolve, reject) => {
        let stateData = JSON.parse(JSON.stringify(state.data))
        stateData.name = stateData.name || getters.getEbsCourseName
        stateData.logo = stateData.logo.fileId ? stateData.logo : null
        stateData.footer = stateData.footer.fileId ? stateData.footer : null
        if (data && data.workspaceDefault !== undefined) {
          stateData.workspaceDefault = data.workspaceDefault
        }
        if (data && data.workspaceId !== undefined) {
          stateData.workspaceId = data.workspaceId
        }
        axios({
          method: 'post',
          url: `${ api.learningactivities }/certificate/content`,
          data: stateData
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Certificate create',
            message: `Certificate has been successfully created.`,
            status: 'success'
          })
          resolve(response)
        }).catch(error => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Certificate create',
            message: `Certificate hasn't been created.`,
            status: 'error'
          })
          reject(error)
        })
      })
    },
    ebsCertificateUpdate ({ state, dispatch }) {
      return new Promise((resolve, reject) => {
        let stateData = JSON.parse(JSON.stringify(state.data))
        stateData.logo = stateData.logo.fileId ? stateData.logo : null
        stateData.footer = stateData.footer.fileId ? stateData.footer : null

        axios({
          method: 'patch',
          url: `${ api.learningactivities }/certificate/content`,
          data: stateData
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Certificate update',
            message: `Certificate has been successfully updated.`,
            status: 'success'
          })
          resolve(response)
        }).catch(error => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Certificate update',
            message: `Certificate hasn't been updated.`,
            status: 'error'
          })
          reject(error)
        })
      })
    },
    ebsCertificateDownload (ctx, payload) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'GET',
          url: payload.url,
          responseType: 'arraybuffer'
        }).then((response) => {
          if (!window.navigator.msSaveOrOpenBlob) {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `${ payload.name } certificate.pdf`)
            document.body.appendChild(link)
            link.click()
          } else {
            window.navigator.msSaveOrOpenBlob(new Blob([response.data]), `${ payload.name } certificate.pdf`)
          }
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsCertificateRemove (ctx, id) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'delete',
          url: `${ api.learningactivities }/certificate/content`,
          data: {
            id
          }
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsCertificatePreviewByContentId (ctx, data) {
      const contentId = data.contentId
      const name = data.name

      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: `${ api.learningactivities }/certificates/preview-by-content${ contentId ? `/` + contentId : '' }`,
          responseType: 'arraybuffer'
        }).then(response => {
          if (!window.navigator.msSaveOrOpenBlob) {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `${ name }-sample.pdf`)
            document.body.appendChild(link)
            link.click()
          } else {
            window.navigator.msSaveOrOpenBlob(new Blob([response.data]), `${ name }-sample.pdf`)
          }
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsCertificatePreviewByCertificateId ({ state }, payload) {
      return new Promise((resolve, reject) => {
        const stateData = JSON.parse(JSON.stringify(state.data))

        const name = payload ? payload.name : stateData.name || 'certificate'
        const html = payload ? payload.html : stateData.html || ''
        const data = { foo: 'bar' }
        let header = {
          file: '',
          type: 'image/png',
          position: payload ? (payload.logo ? payload.logo.position : '') : stateData.logo.position
        }
        let footer = {
          file: '',
          type: 'image/png',
          position: payload ? (payload.footer ? payload.footer.position : '') : stateData.footer.position
        }
        let filesCompletedCount = 0
        let filesArr = []

        const performPreview = () => {
          axios({
            method: 'post',
            url: `${ api.learningactivities }/certificates/sample`,
            data: {
              html,
              data,
              header,
              footer
            },
            responseType: 'arraybuffer'
          }).then((response) => {
            if (!window.navigator.msSaveOrOpenBlob) {
              const url = window.URL.createObjectURL(new Blob([response.data]))
              const link = document.createElement('a')
              link.href = url
              link.setAttribute('download', `${ name }-sample.pdf`)
              document.body.appendChild(link)
              link.click()
            } else {
              window.navigator.msSaveOrOpenBlob(new Blob([response.data]), `${ name }-sample.pdf`)
            }
            resolve()
          }).catch(error => {
            reject(error)
          })
        }

        if ((payload && payload.logo) || stateData.logo.fileId) {
          filesArr.push({ type: 'header', id: payload && payload.logo ? payload.logo.fileId : stateData.logo.fileId })
        }
        if ((payload && payload.footer) || stateData.footer.fileId) {
          filesArr.push({ type: 'footer', id: payload && payload.footer ? payload.footer.fileId : stateData.footer.fileId })
        }
        if (filesArr.length) {
          for (let file of filesArr) {
            toDataURL(`${ api.files }/file/${ file.id }`, (fileUrl) => {
              filesCompletedCount++
              if (file.type === 'header') {
                header.file = fileUrl
              } else if (file.type === 'footer') {
                footer.file = fileUrl
              }
              if (filesCompletedCount === filesArr.length) {
                performPreview()
              }
            })
          }
        } else {
          performPreview()
        }
      })
    },
    ebsCertificateSetName ({ commit }, data) {
      commit('EBS_CERTIFICATE_SET_NAME', data)
    },
    ebsCertificateSetHtml ({ commit }, data) {
      commit('EBS_CERTIFICATE_SET_HTML', data)
    },
    ebsCertificateCheckWorkspaceDefault ({ commit }, data) {
      commit('EBS_CERTIFICATE_SET_WORKSPACEDEFAULT', data)
    },
    ebsCertificateSetWorkspaceDefault (ctx, data) {
      return new Promise((resolve, reject) => {
        const workspaceId = data.workspaceId
        const certificateContentId = data.certificateContentId

        axios({
          method: 'post',
          url: `${ api.learningactivities }/certificate/default/${ workspaceId }/${ certificateContentId }`
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsCertificateUnsetWorkspaceDefault (ctx, workspaceId) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: `${ api.learningactivities }/certificate/remove-default/${ workspaceId }`
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsCertificateSetWorkspaceId ({ commit }, data) {
      commit('EBS_CERTIFICATE_SET_WORKSPACEID', data)
    },
    ebsCertificateSetDefaultsLogoId ({ commit }, data) {
      commit('EBS_CERTIFICATE_SET_LOGO_ID', data)
    },
    ebsCertificateSetDefaultsLogoPosition ({ commit }, data) {
      commit('EBS_CERTIFICATE_SET_LOGO_POSITION', data)
    },
    ebsCertificateSetDefaultsFooterId ({ commit }, data) {
      commit('EBS_CERTIFICATE_SET_FOOTER_ID', data)
    },
    ebsCertificateSetDefaultsFooterPosition ({ commit }, data) {
      commit('EBS_CERTIFICATE_SET_FOOTER_POSITION', data)
    },
    ebsCertificateRemoveDefaultsLogo ({ commit }, data) {
      commit('EBS_CERTIFICATE_REMOVE_LOGO', data)
    },
    ebsCertificateRemoveDefaultsFooter ({ commit }, data) {
      commit('EBS_CERTIFICATE_REMOVE_FOOTER', data)
    },
    ebsCertificateReset ({ commit }, data) {
      commit('EBS_CERTIFICATE_RESET', data)
    },
    ebsCertificateClear ({ commit }, data) {
      commit('EBS_CERTIFICATE_CLEAR', data)
    }
  }
}

export default ebsCertificate
