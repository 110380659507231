import axios from 'axios'
import api from '@/api'
import { User } from '@/User'
import { eventbus } from '@/main'

const data = {
  data: {
    allowForEnrolment: false
  },
  default: '',
  custom: {
    modules: []
  }
}

const ebsEnrolment = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getEbsEnrolment: state => {
      return state.data
    },
    getEbsEnrolmentIsEnrolmentAllowed: (state, getters) => {
      const user = new User()
      const allowForEnrolment = state.data.allowForEnrolment
      const catalogItemId = getters.getEbsCatalogItemId
      const userIsLearner = user.isLearner()

      return (
        catalogItemId &&
      userIsLearner &&
      allowForEnrolment
      )
    },
    getEbsEnrolmentIsCancelEnrolmentAllowed: (state, getters) => {
      const userId = getters.getAuthUserSecondStageTokenDataUserId
      const allowForSelfCancelEnrolment = state.data.isCancellable
      const latestEnrolmentLearnerId = getters.getEbsCatalogItemLatestEnrolmentLearnerId

      return (
        userId === latestEnrolmentLearnerId &&
      allowForSelfCancelEnrolment
      )
    },
    getEbsEnrolmentIsUserEnrolled: (state, getters) => {
      const userId = getters.getAuthUserSecondStageTokenDataUserId
      const latestEnrolmentLearnerId = getters.getEbsCatalogItemLatestEnrolmentLearnerId
      const latestEnrolmentState = getters.getEbsCatalogItemLatestEnrolmentState

      return (
        userId === latestEnrolmentLearnerId &&
      (
        latestEnrolmentState === 'enrolled' ||
      latestEnrolmentState === 'awaiting_feedback' ||
      latestEnrolmentState === 'started'
      )
      )
    },
    getEbsEnrolmentHasUserEnrolmentRequest: (state, getters) => {
      const userId = getters.getAuthUserSecondStageTokenDataUserId
      const latestEnrolmentLearnerId = getters.getEbsCatalogItemLatestEnrolmentLearnerId
      const latestEnrolmentState = getters.getEbsCatalogItemLatestEnrolmentState

      return (
        userId === latestEnrolmentLearnerId &&
      latestEnrolmentState === 'awaiting_approval'
      )
    },
    getEbsEnrolmentModules: (state) => {
      return state.custom.modules
    }
  },
  mutations: {
    EBS_ENROLMENT_CLEAR (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
      state.custom = JSON.parse(JSON.stringify(data.custom))
    },
    EBS_ENROLMENT_SET_ALLOW_FOR_ENROLMENT (state, payload) {
      state.data.allowForEnrolment = payload
    },
    EBS_ENROLMENT_SET (state, payload) {
      state.data = payload
    },
    EBS_ENROLMENT_SET_MODULES (state, payload) {
      state.custom.modules = payload.nodes
    }
  },
  actions: {
    ebsEnrolmentValidate ({ commit, getters }, data) {
      return new Promise((resolve, reject) => {
        const catalogItemId = data ? (data.catalogItemId !== undefined ? data.catalogItemId : getters.getEbsCatalogItemId) : getters.getEbsCatalogItemId
        const learnerId = data ? (data.learnerId !== undefined ? data.learnerId : getters.getAuthUserSecondStageTokenDataID) : getters.getAuthUserSecondStageTokenDataID
        axios({
          method: 'get',
          url: `${api.learningactivities}/enrolment-allowed/${catalogItemId}/${learnerId}`
        }).then(response => {
          commit('EBS_ENROLMENT_SET_ALLOW_FOR_ENROLMENT', response.data)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsEnrolmentGet ({ commit, getters }, data) {
      return new Promise((resolve, reject) => {
        const enrolmentId = data || getters.getEbsCatalogItemLatestEnrolmentId
        axios({
          cancelToken: getters.getStorageCancelToken.token,
          method: 'get',
          url: `${api.learningactivities}/enrolment/${enrolmentId}`
        }).then(response => {
          eventbus.$emit('ebs-enrolment-get')
          commit('EBS_ENROLMENT_SET', response.data)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsEnrolmentGetModules ({ commit, getters }, data) {
      return new Promise((resolve, reject) => {
        const enrolmentId = data

        axios({
          cancelToken: getters.getStorageCancelToken.token,
          method: 'get',
          url: `${api.learningactivities}/learning-history/${enrolmentId}`
        }).then(response => {
          commit('EBS_ENROLMENT_SET_MODULES', response.data)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsEnrolmentCreate ({ commit, getters, dispatch }, data) {
      return new Promise((resolve, reject) => {
        const catalogItemId = data || getters.getEbsCatalogItemId
        axios({
          method: 'post',
          url: `${api.learningactivities}/enrolment`,
          data: {
            catalogItem: catalogItemId
          }
        }).then(response => {
          commit('EBS_ENROLMENT_CLEAR')
          dispatch('defaultNotificationToastsAdd', {
            title: 'Enrolment create',
            message: 'Enrolment has been successfully created.',
            status: 'success'
          })
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsEnrolmentCreateWithBooking ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: `${api.classroom}/book-and-enrol`,
          data: {
            profileId: data.profileId,
            occurrenceId: data.occurrenceId,
            eventId: data.eventId,
            bookingForm: data.bookingForm
          }
        }).then(response => {
          commit('EBS_ENROLMENT_CLEAR')
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsEnrolmentBulkEnrolToCourses ({ dispatch }, data) {
      return new Promise((resolve) => {
        const courses = data.courses
        const userId = data.userId
        let requestsCounter = 0
        let enrollmentsCount = 0
        let index = 0

        const performAction = () => {
          if (index >= courses.length) {
            return resolve()
          }

          const course = courses[index]

          dispatch('ebsEnrolmentAdminEnrol', { learnerId: userId, catalogItemId: course }).then(() => {
            enrollmentsCount++
          }).finally(() => {
            requestsCounter++
            if (requestsCounter === courses.length) {
              if (requestsCounter === enrollmentsCount) {
                dispatch('defaultNotificationToastsAdd', {
                  title: `Learner enrolled to ${enrollmentsCount > 1 ? 'courses' : 'course'}`,
                  status: 'success'
                })
              }
              if (requestsCounter !== enrollmentsCount) {
                dispatch('defaultNotificationToastsAdd', {
                  title: `Learner not enrolled to ${requestsCounter - enrollmentsCount > 1 ? 'courses' : 'course'}`,
                  status: 'error'
                })
              }
              resolve()
            }

            index++
            performAction()
          })
        }

        performAction()
      })
    },
    ebsEnrolmentAssignSuggestedCourses (ctx, data) {
      return new Promise((resolve, reject) => {
        const catalogItems = data.catalogItems
        const learner = data.learner
        const workspaceId = data.workspaceId

        axios({
          method: 'post',
          url: `${api.learningactivities}/enrolment/assign-suggested-courses`,
          data: {
            catalogItems,
            learner,
            workspaceId
          }
        }).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsEnrolmentCancel ({ commit, getters }, data) {
      return new Promise((resolve, reject) => {
        const enrolmentId = data || getters.getEbsCatalogItemLatestEnrolmentId
        axios({
          method: 'post',
          url: `${api.learningactivities}/enrolment/${enrolmentId}/cancel`
        }).then(response => {
          commit('EBS_ENROLMENT_CLEAR')
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsEnrolmentClear ({ commit }) {
      commit('EBS_ENROLMENT_CLEAR')
    },
    ebsEnrolmentAdminEnrol (ctx, data) {
      return new Promise((resolve, reject) => {
        const catalogItemId = data.catalogItemId
        const learnerId = data.learnerId

        axios({
          method: 'post',
          url: `${api.learningactivities}/enrolment/admin`,
          data: {
            catalogItem: catalogItemId,
            learner: learnerId
          }
        }).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsEnrolmentBulkEnrol ({ dispatch }, data) {
      return new Promise((resolve) => {
        const occurrenceId = data.occurrenceId
        const eventId = data.eventId
        const bookingForm = data.bookingForm
        const catalogItemId = data.catalogItemId
        const learners = data.learners
        const courseIsSingleEventTypeWithOneOccurrence = data.courseIsSingleEventTypeWithOneOccurrence
        let learnersMapped

        if (courseIsSingleEventTypeWithOneOccurrence) {
          learnersMapped = learners.map(obj => obj.profileId)
        } else {
          learnersMapped = learners.map(obj => obj.id)
        }

        const ebsEnrolmentCreateWithBooking = (data) => {
          return dispatch('ebsEnrolmentCreateWithBooking', {
            profileId: data,
            occurrenceId,
            eventId,
            bookingForm
          })
        }
        const ebsEnrolmentAdminEnrol = (data) => {
          return dispatch('ebsEnrolmentAdminEnrol', {
            catalogItemId,
            learnerId: data
          })
        }

        let dispatchFn

        if (courseIsSingleEventTypeWithOneOccurrence) {
          dispatchFn = ebsEnrolmentCreateWithBooking
        } else {
          dispatchFn = ebsEnrolmentAdminEnrol
        }

        for (let index in learnersMapped) {
          setTimeout(() => {
            dispatchFn(learnersMapped[index]).finally(() => {
              if (Number(index) === (Number(learnersMapped.length) - 1)) {
                dispatch('defaultNotificationToastsAdd', {
                  title: 'Bulk enrolment create',
                  message: 'Bulk enrolment has been successfully created.',
                  status: 'success'
                })
                resolve()
              }
            })
          }, 100 * index)
        }
      })
    },
    ebsEnrolmentBulkWithdraw ({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        const catalogItemId = data.catalogItemId
        const learners = data.learners
        const learnersIds = learners.map(obj => obj.id)
        const reclaimed = learners.filter(obj => obj.enrolmentState !== 'in_progress' && obj.enrolmentState !== 'awaiting_feedback')

        axios({
          method: 'post',
          url: `${api.learningactivities}/withdraw-enrolment`,
          data: {
            catalogItem: catalogItemId,
            learners: learnersIds
          }
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Enrolments withdrawn',
            message: '',
            status: 'success'
          })
          if (reclaimed.length) {
            dispatch('defaultNotificationToastsAdd', {
              title: `${reclaimed.length} ${reclaimed.length === 1 ? 'licence' : 'licences'} reclaimed`,
              message: '',
              status: 'success'
            })
          }
          resolve(response)
        }).catch(error => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Enrolments not withdrawn',
            message: '',
            status: 'error'
          })
          reject(error)
        })
      })
    }
  }
}

export default ebsEnrolment
