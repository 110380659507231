<template>
    <div
        :class="[
        'form',
        'form__select',
        size.length ? `form__select--${size}` : '',
        overlay ? `form__select--overlay` : '',
        reversed ? `form__select--reversed` : '',
        preventClear ? `form__select--preventclear` : '',
        disabled ? `form__select--disabled` : '',
        hideInput ? 'form__select--hideinput' : '',
        limitActive ? `form__select--limitactive form__select--limititems-${limitItems}` : '',
        limitActive && !limitExpanded ? `form__select--limitcollapsed` : ''
        ]"
    >
        <div class="form__select_top">
            <div class="columns is-vbottom">
                <div class="column is-narrow">
                    <div
                        v-if="label"
                        class="form__select_top_title"
                    >{{label | toCapitalize}}<span class="asterisk" v-if="required">*</span>
                    </div>
                </div>
                <div class="column"></div>
                <div v-if="getItemsDispatch && !disabled && advancedSearchActive" class="column is-narrow">
                    <component-button
                        :text="true"
                        :name="`${name}-advanced-mode-button`"
                        :size="'tiny'"
                        @click="openFormSelectPopTable"
                    >Advanced search
                    </component-button>
                </div>
            </div>
        </div>
        <template v-if="noteActive">
            <div class="form__select_note">
                <component-note>{{ noteContent }}</component-note>
            </div>
        </template>
        <template v-if="$slots.default && hintPositionTop">
            <div class="form__select_hint form__select_hint--top">
                <slot></slot>
            </div>
        </template>
        <template v-if="fieldActive">
            <div class="form__select_field">
                <v-select
                    ref="select"
                    :class="[
                        { invalid: errors.has(toKebabCase(name)) },
                        isType,
                        { multiple: multiple },
                        requestPending || requestPendingCurrentIds ? 'form__select_field_input--request-pending' : '',
                        preventClear ? `preventclear` : ''
                    ]"
                    :multiple="multiple"
                    :close-on-select="!multiple"
                    :options="newItems"
                    :value="isOtherReason ? 'Other' : outputValue"
                    :name="name | toKebabCase"
                    :disabled="disabled"
                    :filter-by="queryFilter"
                    :searchable="searchable"
                    :data-vv-as="dataVvAs ? dataVvAs : label.toLowerCase()"
                    :placeholder="requestPending || requestPendingCurrentIds || requestHold ? 'Loading...' : placeholder"
                    :clearable="!preventClear"
                    v-validate="vValidate"
                    @input="updateValue"
                    @search:focus="emitFocus"
                    @search:blur="emitBlur"
                >
                    <template slot="no-options">{{ requestPending || requestPendingCurrentIds || requestHold ? 'Loading...' : 'Sorry, no matching options.' }}</template>
                    <template slot="selected-option" slot-scope="option">
                        <div v-html="option.customLabel || option.label"></div>
                    </template>
                    <template slot="option" slot-scope="option">
                        <div v-html="option.customLabel || option.label"></div>
                    </template>
                </v-select>
                <component-button
                    v-if="limitActive && outputValue.length > limitItems"
                    :name="'form-select-toggle-limit'"
                    :text="true"
                    :size="'tiny'"
                    @click="switchLimitExpanded"
                >
                    {{ limitExpanded ? 'Minimise' : 'View all' }}
                </component-button>
                <span
                    class="form__error form__select_error"
                    v-if="errors.first(toKebabCase(name))"
                >{{ errors.first(toKebabCase(name)) }}</span>
            </div>
        </template>
        <template v-if="$slots.default && !hintPositionTop">
            <div class="form__select_hint">
                <template v-if="hintButton">
                    <component-button
                        :name="'form-select-hint-button'"
                        :text="true"
                        :size="'tiny'"
                        @click="emitHint"
                    >
                        <slot></slot>
                    </component-button>
                </template>
                <template v-else>
                    <slot></slot>
                </template>
            </div>
        </template>
        <template v-if="otherField || isOtherReason">
            <form-textarea
                :class="'form__select__otherfield'"
                :name="'select-errors'"
                :label="$t('forms.select.label')"
                :disabled="disabled"
                :required="true"
                :overlay="true"
                :v-validate="'required'"
                :placeholder="'Describe reason of cancellation'"
                :value="isOtherReason ? value : otherFieldValue"
                @input="updateValueOther"
                @focus="emitFocus"
                @blur="emitBlur"
            />
        </template>
        <template v-if="advancedSearchActive">
            <pop-table
                ref="formselectpoptable"
                :allow-single="!multiple"
                :get-pagination-getter="getPaginationGetter"
                :get-items-getter="getItemsGetter"
                :get-items-dispatch="getItemsDispatch"
                :get-items-dispatch-data="advancedSearchGetItemsDispatchData || getItemsDispatchData"
                :get-items-dispatch-params="advancedSearchGetItemsDispatchParams || getItemsDispatchParams"
                :filter-select-active="popTableFilterSelectActive"
                :filter-select-items="popTableFilterSelectItems"
                :filter-empty-state-title="popTableFilterEmptyStateTitle"
                :filter-search-placeholder="'Search'"
                :filter-search-queryable="searchQueryable"
                :item-name="itemName"
                :title-text="advancedSearchTitleText || toCapitalize(itemName[itemName.length - 1])"
                :content-text="advancedSearchContentText || `Select ${itemName[itemName.length - 1]} from the table to place them into the field`"
                :cancel-button-text="'Close'"
                :submit-button-animate-loading="false"
                :submit-button-text="'Select'"
                :labels="tableLabels"
                :table-checkbox="true"
                :disallow="value"
                @emit-submit="updateValuePopTable"
            />
        </template>
    </div>
</template>

<script>
import FormTextarea from '@/components/default/forms/FormTextarea'
import { debounce, toKebabCase } from '@/functions'
import PopTable from '@/components/default/shared/PopTable'
import ComponentNote from '@/components/default/shared/ComponentNote'

export default {
  components: {
    ComponentNote,
    PopTable,
    ComponentButton: () => import('@/components/default/shared/ComponentButton'),
    FormTextarea
  },
  name: 'form-select',
  data () {
    return {
      otherField: false,
      otherFieldValue: null,
      dataValue: [],
      requestPending: false,
      requestPendingCurrentIds: false,
      requestHold: false,
      requestSearchActive: false,
      requestResponseData: [],
      requestResponseDataCurrent: [],
      selected: [],
      reversed: false,
      limitExpanded: false,
      queryFilter (option, label, search) {
        let query = search.toLowerCase()
        let value = option.customLabel || option.label

        return value.toLowerCase().indexOf(query) > -1
      }
    }
  },
  props: {
    allowOtherField: {
      type: Boolean,
      default: false
    },
    preselected: {
      type: [Array]
    },
    hideInput: {
      type: Boolean,
      default: false
    },
    limitActive: {
      type: Boolean,
      default: false
    },
    limitItems: {
      type: Number,
      default: 5
    },
    advancedSearchActive: {
      type: Boolean,
      default: true
    },
    advancedSearchGetItemsDispatchData: {
      type: [String, Array, Object],
      default: ''
    },
    advancedSearchGetItemsDispatchParams: {
      type: [String, Array, Object],
      default: ''
    },
    advancedSearchTitleText: {
      type: String
    },
    advancedSearchContentText: {
      type: String
    },
    multiple: {
      type: Boolean,
      default: false
    },
    // array of objects with required keys: label & value
    items: {
      type: [Array, Object],
      default: () => []
    },
    itemsExcluded: {
      type: Array,
      default: () => []
    },
    itemName: {
      type: [String, Array],
      default: 'file'
    },
    label: {
      type: String,
      required: true,
      default: 'Label'
    },
    name: {
      type: String,
      required: true
    },
    value: {},
    other: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: function () {
        return this.$t('forms.select.default')
      }
    },
    isType: {
      type: String,
      // one of: search/select
      default: 'search'
    },
    searchable: {
      type: Boolean,
      default: true
    },
    required: {
      type: Boolean,
      default: false
    },
    popTableFilterSelectActive: {
      type: Boolean,
      default: false
    },
    popTableFilterSelectItems: {
      type: Array,
      default: () => []
    },
    popTableFilterEmptyStateTitle: {
      type: String
    },
    size: {
      type: String,
      default: ''
    },
    hintButton: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    overlay: {
      type: Boolean,
      default: false
    },
    //
    dataVvAs: {
      type: String,
      default: 'select'
    },
    vValidate: {
      type: [String, Array, Object],
      default: ''
    },
    preventClear: {
      type: Boolean,
      default: false
    },
    //
    searchQueryable: {
      type: Boolean,
      default: false
    },
    getPaginationGetter: {
      type: String
    },
    getItemsGetter: {
      type: String
    },
    getItemsGetterParams: {
      type: [String, Object]
    },
    getItemsGetterMapped: {
      type: String
    },
    getItemsGetterMappedParams: {
      type: [String, Object]
    },
    getItemsDispatch: {
      type: String
    },
    getItemsDispatchData: {
      type: [String, Array, Object],
      default: ''
    },
    getItemsDispatchPageSize: {
      type: Number,
      default: 12
    },
    getItemsDispatchParams: {
      type: String,
      default: ''
    },
    tableLabels: {
      type: Array
    },
    itemsExtra: {
      type: [Array, Object],
      default: () => []
    },
    noteActive: {
      type: Boolean,
      default: false
    },
    noteContent: {
      type: String
    },
    fieldActive: {
      type: Boolean,
      default: true
    },
    hintPositionTop: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    watchComputed () {
      return JSON.stringify(this.objectValue) + JSON.stringify(this.dataValue)
    },
    //
    slots: function () {
      return this.$slots
    },
    //
    newItems () {
      const selected = this.selected.length ? this.selected : []
      const itemsExtra = this.itemsExtra.length ? this.itemsExtra : []
      const itemsExcluded = this.itemsExcluded
      let arr

      if (this.getItemsDispatch) {
        if (this.requestPending || this.requestPendingCurrentIds || this.requestHold) {
          arr = []
        } else if (this.getItemsGetterMapped || this.getItemsGetter) {
          arr = [...this.requestResponseData, ...this.requestResponseDataCurrent]
        } else {
          arr = this.items
        }
      } else if (this.getItemsGetterMapped || this.getItemsGetter) {
        const dataItemsGetter = this.getItemsGetterParams ? this.$store.getters[this.getItemsGetter](this.getItemsGetterParams) : this.$store.getters[this.getItemsGetter]
        const dataItemsGetterMapped = this.getItemsGetterMappedParams ? this.$store.getters[this.getItemsGetterMapped](this.getItemsGetterMappedParams) : this.$store.getters[this.getItemsGetterMapped]
        arr = this.getItemsGetterMapped ? dataItemsGetterMapped : dataItemsGetter
      } else {
        arr = this.items
      }
      if (this.other && !arr.some(obj => obj.value === 'other')) {
        arr.push({ label: 'Other', value: 'other' })
      }

      arr = [...arr, ...selected, ...itemsExtra]
      arr = arr.filter((obj, index) => {
        return arr.findIndex(findObj => findObj.value === obj.value) === index
      })

      if (itemsExcluded && itemsExcluded.length) {
        arr = arr.filter(obj => !itemsExcluded.includes(obj.value))
      }

      return arr || []
    },
    selectedValue () {
      return this.selected.map(obj => {
        return obj.value
      })
    },
    // eslint-disable-next-line vue/return-in-computed-property
    objectValue () {
      const value = this.value ? this.value : this.selectedValue

      if (value === undefined || value === null) {
        return []
      }

      if ((value.constructor === String && value) ||
      value.constructor === Boolean ||
      (value.constructor === Number && value) ||
      (value.constructor === Array && value[0]) ||
      (value.constructor === Object && value[Object.keys(value)[0]])) {
        if (this.multiple) {
          const values = value

          if (this.newItems && this.newItems.some(obj => obj.value === 'other')) {
            for (let i = 0; i < values.length; i++) {
              if (!this.newItems.some(obj => obj.value === values[i])) {
                this.setOtherField(true)
                this.setOtherFieldValue(values[i].constructor === Object ? values[i].value : values[i])
                break
              }
            }
          }

          if (this.newItems) {
            return this.newItems.filter((object) => {
              return values.indexOf(object.value) > -1
            })
          }
        } else if (!this.multiple && value[0] === 'other') {
          const values = value

          this.setOtherField(true)
          this.setOtherFieldValue(values[1])

          return this.newItems.filter((object) => {
            return object.value === values[0]
          })
        } else {
          return this.newItems.filter((object) => {
            if (typeof value === 'string') {
              return object.value === String(value)
            } else if (typeof value === 'number') {
              return object.value === Number(value)
            } else if (typeof value === 'boolean') {
              return object.value === value
            } else if (value.constructor === Array) {
              return object.value === value[0]
            } else {
              return object.value[Object.keys(object.value)[0]] === value[Object.keys(value)[0]]
            }
          })
        }
      } else {
        return []
      }
    },
    outputValue () {
      let value

      if (this.objectValue.length) {
        value = this.objectValue
      } else if (this.dataValue.length) {
        value = this.dataValue
      } else {
        value = ''
      }

      return value
    },
    isOtherReason () {
      return !this.outputValue && this.value && this.value.constructor === String && this.allowOtherField
    }
  },
  watch: {
    value (value, oldValue) {
      this.dataValue = []
      this.selected = []
      if (value && value.length && JSON.stringify(value) !== JSON.stringify(oldValue)) {
        if (value.constructor === String && this.newItems.findIndex(obj => obj.value === this.value) === -1) {
          this.performGetItems()
        }
        this.performGetCurrentIds()
      }
    },
    watchComputed () {
      let value = this.objectValue ? this.objectValue.length ? this.objectValue : this.dataValue : []
      let str
      if (!value.length) {
        str = ''
      } else if (value.length === 1) {
        str = `${this.name}: ${value[0].customLabel || value[0].label}`
      } else {
        str = `${this.name}: ${value[0].customLabel || value[0].label}, ${value.length - 1} more...`
      }
      this.emitSelected(str)
      this.emitFilter(value)
    },
    getItemsDispatch () {
      this.performGetItems()
    },
    getItemsDispatchData (newValue, oldValue) {
      if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
        this.performGetItems()
      }
    }
  },
  methods: {
    openFormSelectPopTable () {
      this.$refs.formselectpoptable.openPop()
    },
    emitFocus () {
      this.$nextTick(() => {
        const overlay = document.getElementById('app')
        const select = this.$refs.select.$el
        const dropdown = select.querySelector('.vs__dropdown-menu')

        const dropdownPosition = dropdown.getBoundingClientRect()

        if (dropdownPosition.top + dropdown.clientHeight > overlay.clientHeight) {
          this.reversed = true
        }
      })

      const field = this.$validator.errors.items.find(obj => obj.field === toKebabCase(this.name))
      this.$validator.pause()
      if (field) {
        this.$validator.reset(field)
      }
      if (this.requestSearchActive) {
        this.performGetItems()
        this.requestSearchActive = false
      }
      this.$emit('emit-focus')
    },
    emitBlur () {
      const field = this.$validator.errors.items.find(obj => obj.field === toKebabCase(this.name))
      this.$validator.resume()
      if (field) {
        this.$validator.reset(field)
      }
      this.$emit('emit-blur')
      this.$refs.select.onEscape()

      setTimeout(() => {
        this.reversed = false
      }, 100)
    },
    clearData () {
      this.$refs.select.clearSelection()
      this.dataValue = []
      this.updateValue([])
    },
    emitSelected (data) {
      this.$emit('emit-selected', data)
    },
    emitFilter (data) {
      this.$emit('emit-filter', data)
    },
    emitHint: function () {
      this.$emit('emit-hint')
    },
    setOtherField: function (value) {
      this.otherField = value
    },
    setOtherFieldValue: function (value) {
      this.otherFieldValue = value
    },
    updateValue: function (value) {
      if (value) {
        this.$emit('input', value || { value: '' })
        this.selected = this.multiple ? value : [value]
      } else {
        this.$emit('input', '')
        this.selected = []
        return
      }
      this.dataValue = value
      if (!this.multiple) {
        this.selected = []
      }
      if (value.constructor === Object) {
        let truthy = false
        if (value.value.constructor === String) {
          truthy = value.value.toLowerCase() === 'other'
        }
        this.otherFieldValue = ''
        this.otherField = truthy
      } else if (value.constructor === Array) {
        let truthy = value.some(obj => obj.value === 'other')
        this.otherFieldValue = ''
        this.otherField = truthy
      }
    },
    updateValuePopTable (data) {
      let valueMapped = []
      let valueSelected
      let outputData
      if (this.multiple) {
        if (this.value && this.value.length) {
          valueMapped = JSON.parse(JSON.stringify(this.value)).map(obj => {
            const extendedValue = this.requestResponseDataCurrent.find(extObj => extObj.value === obj)
            if (extendedValue) {
              return extendedValue
            } else {
              return {
                value: obj
              }
            }
          })
        }
        valueSelected = data.map(obj => {
          return {
            value: obj.id,
            label: obj.name + obj.id,
            customLabel: obj.name,
            customValue: obj.customValue,
            extraData: obj.extraData
          }
        })
        outputData = [...valueMapped, ...valueSelected]
        outputData = outputData.filter((obj, index) => {
          return outputData.findIndex(findObj => findObj.value === obj.value) === index
        })
      } else {
        valueSelected = {
          value: data[0].id,
          label: data[0].name + data[0].id,
          customLabel: data[0].name,
          extraData: data[0].extraData
        }
        outputData = valueSelected
      }

      this.selected = this.multiple ? outputData : [outputData]
      this.dataValue = this.multiple ? outputData : [outputData]
      this.$emit('input-table', outputData)
      setTimeout(() => {
        this.performGetItems()
      }, 100)
    },
    updateValueOther: function (value) {
      let objectValues = JSON.parse(JSON.stringify(this.objectValue))
      let newValue = JSON.parse(JSON.stringify(this.objectValue))
      if (this.multiple) {
        if (value && !objectValues.some(obj => obj.value === value)) {
          newValue.push({ label: value, value: value })
        }
      } else {
        newValue = { label: value, value: value }
      }
      this.otherFieldValue = value
      this.$emit('input', newValue)
    },
    performGetItems (value) {
      if (this.getItemsDispatch && !this.requestPending) {
        this.requestPending = true
        const requestData = this.getItemsDispatchData
        const currentValue = this.value ? (this.value.constructor === Array ? this.value.join(',') : this.value) : this.value
        const page = 1
        const pageSize = this.getItemsDispatchPageSize
        const query = value ? encodeURIComponent(value) : ''
        const urlParams = `?page=${page}&pageSize=${pageSize}${query ? `&filter=${query}` : ''}` + this.getItemsDispatchParams

        this.$store.dispatch(this.getItemsDispatch, this.getPaginationGetter ? { queryUrlParams: urlParams, requestData } : { requestData }).then(response => {
          const dataItemsGetter = this.getItemsGetterParams ? this.$store.getters[this.getItemsGetter](this.getItemsGetterParams) : this.$store.getters[this.getItemsGetter]
          const dataItemsGetterMapped = this.getItemsGetterMappedParams ? this.$store.getters[this.getItemsGetterMapped](this.getItemsGetterMappedParams) : this.$store.getters[this.getItemsGetterMapped]
          this.requestResponseData = this.getItemsGetterMapped ? dataItemsGetterMapped : dataItemsGetter
          this.$emit('get-items', response)
          this.$emit('request-selected', this.requestResponseData ? this.requestResponseData.find(obj => obj.value === currentValue) : '')
        }).catch(() => {
          this.requestResponseData = []
        }).finally(() => {
          this.requestHold = false
          this.requestPending = false
        })
      }
    },
    performGetCurrentIds () {
      const currentValue = this.value ? (this.value.constructor === Array ? this.value.join(',') : this.value) : this.value

      if (this.getItemsDispatch && this.getPaginationGetter && currentValue && !this.requestPendingCurrentIds) {
        this.requestPendingCurrentIds = true
        const requestData = this.getItemsDispatchData
        const urlParams = `?page=1&pageSize=9999${currentValue && currentValue.length ? `&filters[ids]=${currentValue}` + this.getItemsDispatchParams : ''}`

        this.$store.dispatch(this.getItemsDispatch, { queryUrlParams: urlParams, requestData }).then(response => {
          const dataItemsGetter = this.getItemsGetterParams ? this.$store.getters[this.getItemsGetter](this.getItemsGetterParams) : this.$store.getters[this.getItemsGetter]
          const dataItemsGetterMapped = this.getItemsGetterMappedParams ? this.$store.getters[this.getItemsGetterMapped](this.getItemsGetterMappedParams) : this.$store.getters[this.getItemsGetterMapped]
          this.requestResponseDataCurrent = this.getItemsGetterMapped ? dataItemsGetterMapped : dataItemsGetter
          this.$emit('get-items-current', response)
        }).catch(() => {
          this.requestResponseDataCurrent = []
        }).finally(() => {
          this.requestHold = false
          this.requestPendingCurrentIds = false
        })
      }
    },
    initializePreselectedValue () {
      if (this.preselected && this.preselected.length) {
        this.updateValue(this.preselected)
      }
    },
    switchLimitExpanded () {
      this.limitExpanded = !this.limitExpanded
    }
  },
  created () {
    this.initializePreselectedValue()
  },
  mounted () {
    this.$eventBus.$on(`${this.name}-trigger-get-items`, this.performGetItems)
    this.performGetItems()
    if (this.value) {
      this.performGetCurrentIds()
    }
    const searchInput = this.$el.getElementsByClassName('vs__search')[0]
    const handleDebounce = debounce(() => {
      this.performGetItems(searchInput.value)
    }, 800)
    if (this.searchQueryable) {
      searchInput.addEventListener('keyup', () => {
        this.requestHold = true
        this.requestSearchActive = true
        handleDebounce()
      })
    }
  },
  beforeDestroy () {
    this.$eventBus.$off(`${this.name}-trigger-get-items`)
  },
  inject: ['$validator']
}
</script>

<style lang="scss">
    @import "~@/assets/scss/components/default/forms/formselect";
</style>
